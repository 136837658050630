import { WebStorageStateStore } from 'oidc-client-ts';
import React, { useEffect } from 'react';
import { AuthProvider, hasAuthParams, AuthProviderProps, useAuth as useOidcAuth } from 'react-oidc-context';
import { useAppProps } from '~/lib/useAppProps';
import { FourOOne } from '~/pages/ErrorPage/401';
import { LoadingPage } from '~/pages/LoadingPage';

const OIDC_USER_STORAGE_PREFIX = 'oidc.user';
// Note: Implemented to have more control over the use of "id_token" via the "getAccessToken" method.
// This is required to validate against the source of truth i.e. the browser-level storage for valid OIDC key.
// Without a valid OIDC key, we consider the session not authenticated.
export function useAuth() {
  const { oauth } = useAppProps();
  const auth = useOidcAuth();
  return {
    isLoading: auth.isLoading,
    isAuthenticated: auth.isAuthenticated,
    getAccessToken: () => {
      // Note: This condition is due to cognito not being fully oauth compatible.
      if (oauth.issuer) {
        return auth.user?.access_token;
      }
      const aud = auth.user?.profile?.aud;
      const iss = auth.user?.profile?.iss;
      if (iss && aud && typeof aud === 'string') {
        if (window.localStorage.getItem(`${OIDC_USER_STORAGE_PREFIX}:${iss}:${aud}`)) {
          return auth.user?.id_token;
        }
      }
      return undefined;
    },
    logout: async () => {
      // Note: This condition is due to cognito not being fully oauth compatible.
      if (oauth.issuer) {
        await auth.signoutRedirect();
      } else {
        await auth.removeUser();
        await auth.revokeTokens();
        await auth.clearStaleState();
        window.location.href = `${oauth.logoutUri}?client_id=${auth.settings.client_id}&logout_uri=${auth.settings.post_logout_redirect_uri}&redirect_uri=${auth.settings.redirect_uri}&response_type=code`;
      }
    },
  };
}

function AutoSignIn(props: { children: JSX.Element }) {
  const auth = useOidcAuth();
  useEffect(() => {
    if (!hasAuthParams() && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading) {
      auth.signinRedirect();
    }
  }, [auth]);

  if (auth.isLoading || !auth.isAuthenticated) {
    return <LoadingPage />;
  }

  if (auth.error) {
    //TODO: report {auth.error.message}
    return <FourOOne />;
  }

  return props.children;
}

export function Authenticator(props: { children: JSX.Element }) {
  const appProps = useAppProps();

  const authConfig: AuthProviderProps = {
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    authority: appProps.oauth.authority || appProps.oauth.issuer || '',
    client_id: appProps.oauth.clientId,
    redirect_uri: `${window.location.protocol}//${window.location.host}`,
    post_logout_redirect_uri: `${window.location.protocol}//${window.location.host}/login`,
    revokeTokensOnSignout: true,
  };
  return (
    <AuthProvider {...authConfig}>
      <AutoSignIn>{props.children}</AutoSignIn>
    </AuthProvider>
  );
}
