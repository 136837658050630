import { formatNonTimezoneDate } from '~/utils';
import { Flex, Heading } from '@applyboard/crystal-ui';
import { PassportOutlineIcon } from '@applyboard/ui-icons';
import { LabelValue } from '~/components/uie-fix/LabelValue';
import { useAppProcessing } from '~/pages/ApplicationDetails/context';
import { VisaOutcomeRequest } from '../ApplicationProcessing/VisaOutcome/VisaOutcomeRequest';
import { Card } from './Card';
import { AttachedDocuments } from './shared/AttachedDocuments';

export function StatusAndCitizenship() {
  const {
    application: { statusAndCitizenship },
  } = useAppProcessing();
  const passportExpiryDate =
    statusAndCitizenship.passportExpiryDate === 'NOT_AVAILABLE'
      ? 'NOT_AVAILABLE'
      : formatNonTimezoneDate(new Date(statusAndCitizenship.passportExpiryDate));
  return (
    <Card id="statusAndCitizenship" icon={PassportOutlineIcon} title="Status and Citizenship">
      <Flex justify="start" direction="column" gap={8}>
        <Heading level={4} variant="titleS">
          Citizenship Information
        </Heading>
        <Flex gap={8}>
          <LabelValue label="EXPIRY DATE" value={passportExpiryDate} />
          <LabelValue label="PASSPORT NUMBER" value={statusAndCitizenship.passportNumber} />
        </Flex>
        <AttachedDocuments
          attachmentDocuments={Object.values(statusAndCitizenship.documents)}
          showTitle
          section="statusAndCitizenship"
          sectionReference={statusAndCitizenship.key}
          types={['PASSPORT']}
        />
        <Heading level={4} variant="titleS">
          Status Information
        </Heading>
        <LabelValue label="CANADA STATUS" value={statusAndCitizenship.destinationCountryStatus.name} />
        <AttachedDocuments
          disableAdditionalRequest
          attachmentDocuments={Object.values(statusAndCitizenship.documents)}
          showTitle
          section="statusAndCitizenship"
          sectionReference={statusAndCitizenship.key}
          noRequestForMissingDocuments
          types={['DESTINATION_COUNTRY_STATUS', 'STUDY_PERMIT', 'WORK_PERMIT', 'VISITOR_TOURIST_VISA', 'VISA_OUTCOME']}
        />
        <VisaOutcomeRequest />
      </Flex>
    </Card>
  );
}
