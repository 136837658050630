import React from 'react';
import styled from '@emotion/styled';
import { Accordion, Flex } from '@applyboard/crystal-ui';
import { CheckMarkOutlineIcon, WarningOutlineIcon } from '@applyboard/ui-icons';

export type AccordionWithIntentProps = {
  id: string;
  title: React.ReactNode;
  children: React.ReactNode;
  intent: 'positive' | 'negative' | 'warning';
};

export function AccordionWithIntent(props: AccordionWithIntentProps) {
  const [rotateAnimation, setRotateAnimation] = React.useState(false);

  // This function corrects caret icon animation due to CDS's Acordion bug: https://applyboard.atlassian.net/browse/UIE-3096
  function handleCaretAnimation() {
    setRotateAnimation((prev) => !prev);
  }
  return (
    <VisualEnhancement intent={props.intent} rotateAnimation={rotateAnimation}>
      <Accordion onChange={() => handleCaretAnimation()}>
        <Accordion.Item
          autoOpen={false}
          id={props.id}
          icon={props.intent === 'positive' ? CheckMarkOutlineIcon : WarningOutlineIcon}
          title={props.title}
        >
          <Flex p={4} direction="column">
            {props.children}
          </Flex>
        </Accordion.Item>
      </Accordion>
    </VisualEnhancement>
  );
}

const intentColors = {
  negative: {
    background: '#FFEBEE',
    backgroundHover: '#FDCFDB',
    text: '#76132D',
    icon: '#FA3E70',
  },
  positive: {
    background: '#DFF3F0',
    backgroundHover: '#B0E5DC',
    text: '#064439',
    icon: '#0A987E',
  },
  warning: {
    background: '#FEEDC8',
    backgroundHover: '#FED680',
    text: '#612D10',
    icon: '#D96432',
  },
};

const VisualEnhancement = styled.div<{ intent: keyof typeof intentColors; rotateAnimation: boolean }>`
  & > div > div {
    border: none;
  }

  & > div > div > button {
    background-color: ${({ intent }) => intentColors[intent].background};
    border: 1px solid ${({ intent }) => intentColors[intent].background};
  }

  & > div > div > button:hover {
    background-color: ${({ intent }) => intentColors[intent].backgroundHover};
    border: 1px solid ${({ intent }) => intentColors[intent].backgroundHover};
    color: ${({ intent }) => intentColors[intent].text};
  }

  & > div > div > button > div > span > span,
  & > div > div > button > div > div > div {
    color: ${({ intent }) => intentColors[intent].text} !important;
  }

  & > div > div > button > div > span > span > svg {
    transform: ${({ rotateAnimation }) => (rotateAnimation ? 'rotate(0deg)' : 'rotate(-180deg)')};
    transition: ${({ rotateAnimation }) => (rotateAnimation ? '0.3s' : '300ms')};
  }

  & > div > div > button > div > div > span {
    color: ${({ intent }) => intentColors[intent].icon} !important;
  }

  & > div > div > div {
    border-left: 1px solid #d7dae8;
    border-right: 1px solid #d7dae8;
    border-bottom: 1px solid #d7dae8;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top: none;
  }
`;
